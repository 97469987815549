.main-content {
    padding: 20px; 
    overflow: auto; 
    width: 100%;
  }

.app-container {
    display: flex; 
}

.menu {
    border: none;        
    background: none;    
    text-decoration: none;
    padding-top: 150px;
    margin-right: 100px;
}

.menu-text {
  font-size: 25px;
  color: #b6ff9d;
  padding-left: 10px;
}

.Link {
  text-decoration: none;
}

/* .menu-text:visited,
.menu-text:focus,
.menu-text:active,
.menu-text:hover {
 
}

/* Removes Purple */
/* .Link:visited,
.Link:focus,
.Link:active,
.Link:hover {
   
} */


  /*   Credit to https://codepen.io/rothkj1022/pen/oQxWWN  */
.menu-icon {
  color: #b6ff9d;
  z-index: 1;
  filter: grayscale(100%);
  transition: filter .5s; 
}

.menu-icon svg {
  width: 40px; /* Adjust the width to your desired size */
  height: 40px; /* Adjust the height to your desired size */
}

/*   Credit to https://codepen.io/rothkj1022/pen/oQxWWN  */

.menu-icon:hover {
  filter: none;
}



  /*   HOME CONTENT   */

  .home-container {
    
  }
  .home-content {
    
  }

  .pfp-style {
    position: absolute;
    top: 875px;
    left: 52.5px;
    z-index: 0; /* Place the image below the icon */
  }



  /*  PROJECT CONTENT  */

  /**  Corner Border Method Credit: https://codeconvey.com/css-border-corner/?expand_article=1 **/
  
  /* Odd Images */

  /* .center-decor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0%;
    margin-top: -5%;
    left: 45%;
    height: 1750px;
    position: absolute;
  } */

  @media (max-width: 768px) {
    .project-container {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      align-items: center; /* Center align items */
    }
    
    .centerDesign {
      max-width: 150px; /* Adjust max-width for smaller screens */
    }
  }

  .centerDesign {
    height: 80%;
    width: 10px;
  }

  .corner {
    display: inline-block;
    padding: 25px;
    position: relative;
    width: auto;
    margin-top: -20px;
  }

  .corner-wrap {

  }

  .corner img {
      position: relative;
      display: block;
      margin: 0;
      padding: 0;
      z-index: 5;
  }

  .corner::before,
  .corner::after {
      content: "";
      position: absolute;
      background: transparent; /* Set background to transparent */
  }

  .corner::before {
      width: 20px;
      height: 20px;
      border-top: 2px solid #b6ff9d;
      border-left: 2px solid #b6ff9d;
      top: -2px;
      left: -2px;
      z-index: 1;
      content: "";
  }

  .corner::after {
      width: 20px;
      height: 20px;
      border-bottom: 2px solid #b6ff9d;
      border-right: 2px solid #b6ff9d;
      bottom: -2px;
      right: -2px;
      z-index: 1;
      content: "";
  }

  /* Even Images */
  .corner-even {
    display: inline-block;
    padding: 25px;
    position: relative;
    width: auto;
    margin-top: -20px;
  }

  .corner-even img {
      position: relative;
      display: block;
      margin: 0;
      padding: 0;
      z-index: 5;
  }

  .corner-even::before,
  .corner-even::after {
      content: "";
      position: absolute;
      background: transparent; /* Set background to transparent */
  }

  .corner-even::before {
      width: 20px;
      height: 20px;
      border-top: 2px solid #b6ff9d;
      border-right: 2px solid #b6ff9d; /* Use border-right instead of border-left */
      top: -2px;
      right: -2px;
      z-index: 1;
      content: "";
  }

  .corner-even::after {
      width: 20px;
      height: 20px;
      border-bottom: 2px solid #b6ff9d;
      border-left: 2px solid #b6ff9d;
      bottom: -2px;
      left: -2px; /* Use left instead of right */
      z-index: 1;
      content: "";
  }

  /* End Border Styling */

  .social-project-links {
    cursor: pointer;
  }

  .social-project-links button {
    transition: transform 0.3s ease;
  }

  .social-project-links button:hover {
    transform:  scale(1.2);
  }

  .project-container {
    position: relative;
  }

 /* Updated style for the grid container */
  .grid-container {
    z-index: 1;
  }
  
  /* Style for the grid items (boxes) */
  .box-left {
    display: flex;
    text-align: left;
    padding-top: 10%;
    padding-left: 5%;
    z-index: 1;
  }

  .box-left a {
    height: 100%;
  }


  /* .particle-sim-header {
    position: absolute;
    font-size: 50px;
    color: white;
    padding-left: 800px;
    padding-top: 150px;
  }

  .particle-sim-body {
    position: absolute;
    font-size: 30px;
    color: rgb(190, 190, 190);
    padding-left: 800px;
    padding-top: 230px;
  } */

  .particle-sim-header {
    padding-left: 20%;
    margin-top: -2%;
    z-index: 1;
    color: white;

  }

  .bently-adventures-header {
    padding-left: 20%;
    margin-top: -2%;
    z-index: 1;
    color: white;
  }

  .crows-speak-header {
    padding-left: 20%;
    margin-top: 0%;
    z-index: 1;
    color: white;
  }

  .code-links {
    border: none;        
    background: none;    
    text-decoration: none;
  }

  .youtube-icon {
    border: none;        
    background: none;    
    text-decoration: none;
  }

  .github-icon {
    border: none;        
    background: none;    
    text-decoration: none;
    margin-right: 5px;
  }

  .linkedin-icon {
    border: none;        
    background: none;    
    text-decoration: none;
  }


  .buttons-container  {
  }

  .socials-button-container {
    display: flex;
    justify-content: flex-start;
    margin-right: 450px;
    margin-top: 50px;
    cursor: pointer;
  }
  
  .social-buttons-styling {
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    margin: 0 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .socials-button-container button {
    transition: transform 0.3s ease;
  }
  
  .socials-button-container button:hover {
    transform:  scale(1.2);
  }


  /*    Experience Content   */
  .experience-opaque {
    position: absolute;
    top: -140px;
    left: 55px;
    width: 180%;
    height: 20%;
    object-fit: cover;
    z-index: 0; /* Place the image below the icon */
    opacity: 0.5;
    font-size: 150px;
    color:#5a5a5a;
    opacity: 40%;
  }

  .experience-container {

  }

  .bottom-row-experience {
  
  }

  .top-row-experience {
    
  }

  .top-row-item {
    font-size: 24px;
    color: white;
    margin: 0;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }

    .bottom-row {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .bottom-row-item {
        color: white;
        font-size: 24px;
        align-items: flex-end;
    }

  .left {
    align-self: flex-start;
  }

  .right {
    align-self: flex-end;
  }

  .center {
    text-align: center;
  }


  /*     About Content   */
  .about-opaque {
    position: absolute;
    top: -140px;
    left: 55px;
    width: 180%;
    height: 20%;
    object-fit: cover;
    z-index: 0; /* Place the image below the icon */
    opacity: 0.5;
    font-size: 150px;
    color:#5a5a5a;
    opacity: 20%;
  }

  .about-container {
    /* display: 'flex';
    flex-direction: 'column';
    align-items: 'flex-end';
    font-size: 30px;
    color: white; */
  }

  .about-content {
    /* align-items: right; */
  }

  .youtube-icon-about {
    border: none;        
    background: none;    
    text-decoration: none;
  }

  .github-icon-about {
    border: none;        
    background: none;    
    text-decoration: none;
    margin-right: 10px;
  }

  .linkedin-icon-about {
    border: none;        
    background: none;    
    text-decoration: none;
    margin-right: 10px;
  }

  .discord-icon-about {
    border: none;        
    background: none;    
    text-decoration: none;
    margin-right: 10px;
  }

  .resume-button-container {
    margin-left: auto;
  }

  /* Credit to https://codepen.io/jennyhmc/pen/qBavyVg */
  .resume-btn {
    background: #383b3a;
    color: #1bbb85;
    border: 2px solid #1bbb85;
    border-radius: 5px; 
    width: 300px;
    height: 80px;
    margin-top: 50px;
    transition: background 400ms ease-out,
    color 400ms ease-out; 
}

  /* Credit to https://codepen.io/jennyhmc/pen/qBavyVg */
.resume-btn:hover {
    background: #1bbb85;
    color: #383b3a;
    border-radius: 5px; 
}

/* revised-styles.css */

.experience-container {
  color: #ffffff;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
}

.experience-header {
  text-align: center;
  margin-bottom: 50px;
}

.experience-title {
  font-size: 36px;
}

.experience-card {
  display: flex;
  align-items: center;
}

.experience-card-content {
  flex: 1;
  padding-right: 0px;
}

.experience-card-image {
  flex: 1;
  text-align: center;
}

.experience-logo {
  max-width: 300px;
  height: auto;
}

.experience-job-title {
  font-size: 60px;
  margin-bottom: 0px;
}

.experience-position {
  font-size: 40px;
  color: #b6ff9d;
  margin-top: 0px;
  margin-bottom: 15px;
}

.experience-description {
  font-size: 30px;
  margin-top: 15px;
}





